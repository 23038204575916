var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-8",
    staticStyle: {
      "max-width": "1785px"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg"
  }, [_c('v-card-title', {
    staticClass: "primary white--text pa-3"
  }, [_vm.nothingToShow ? _c('div', {
    staticClass: "pa-2"
  }) : _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "primary",
      "dark": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.schedules, function (s) {
    return _c('v-tab', {
      key: s.xid
    }, [_vm._v(_vm._s(s.title))]);
  }), 1), _c('v-btn-toggle', {
    staticClass: "mt-2",
    attrs: {
      "group": "",
      "mandatory": "",
      "dark": "",
      "dense": ""
    },
    model: {
      value: _vm.toggle_available,
      callback: function callback($$v) {
        _vm.toggle_available = $$v;
      },
      expression: "toggle_available"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": "available"
    }
  }, [_vm._v("Available")]), _c('v-btn', {
    attrs: {
      "value": "unavailable"
    }
  }, [_vm._v("Upcoming")]), _c('v-btn', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("All")])], 1)], 1)])], 1), _vm.nothingToShow ? _c('p', {
    staticClass: "ma-4 pt-2 text-body-2"
  }, [_vm._v(" There are no schedules currently assigned to you. ")]) : _vm._e(), _c('v-tabs-items', {
    staticClass: "transparent",
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.schedules, function (s) {
    return _c('v-tab-item', {
      key: s.xid,
      staticClass: "transparent",
      attrs: {
        "transition": false
      }
    }, [_c('ScheduleListing', {
      attrs: {
        "scheduleId": s.xid,
        "availability": _vm.toggle_available
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }