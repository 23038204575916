<template>
  <v-container class='pt-8' fluid style="max-width:1785px;">

    <v-card class='rounded-lg'>

      <v-card-title class='primary white--text pa-3'>

        <div class="pa-2" v-if="nothingToShow">
        </div>

        <div v-else style="width:100%;">

          <v-tabs v-model="tab" background-color="primary" dark show-arrows>
            <v-tab v-for="s in schedules" :key="s.xid">{{ s.title }}</v-tab>
          </v-tabs>

          <v-btn-toggle v-model="toggle_available" group mandatory dark dense class="mt-2">
            <v-btn value="available">Available</v-btn>
            <v-btn value="unavailable">Upcoming</v-btn>
            <v-btn value="all">All</v-btn>
          </v-btn-toggle>

        </div>

      </v-card-title>

    </v-card>

    <p class="ma-4 pt-2 text-body-2" v-if="nothingToShow">
      There are no schedules currently assigned to you.
    </p>

    <v-tabs-items v-model="tab" class="transparent">
      <v-tab-item
        v-for="s in schedules" :key="s.xid"
        :transition="false"
        class="transparent"
      >
        <ScheduleListing :scheduleId="s.xid" :availability="toggle_available" />
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<style lang="scss">
.v-main{ overflow-y: auto; }
</style>


<script>
import { mapGetters,  mapActions } from 'vuex';
import ScheduleListing from '../../components/Schedule/Listing.vue';

export default {
  name: 'Jobs',
  components: {
    ScheduleListing
},
  props: {
    stageId: { type: String, required: false, default: null },
  },
  data(){ return {
    loading: false,
    tab: null,
    schedule: null,
    schedules: [],
    toggle_available: null,
  }},
  computed:{
    ...mapGetters("AUTH", ["USER"]),
    nothingToShow(){ return !this.loading && !this.schedules.length; }
  },
  watch: {
    $route: { handler(){ this.SET_TITLE("My Work"); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "CONFIRM", "NAVIGATE"]),
    ...mapActions("API", ["API"]),

    refresh(){
      this.schedule = null;
      this.loadSchedules();
    },

    loadSchedules(){
      this.schedules = [];
      this.loading = true;
      this.API({ endpoint: `users/${this.USER.xid}/schedules` })
      .then(data => { this.schedules = data })
      .finally(() => { this.loading  = false; })
    }

  },
  mounted(){
    this.refresh();
  }
}
</script>
