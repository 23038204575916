<template>
  <div ref="sortable">
      <v-card v-for="task in displayTasks" :key="task.xid" class="my-2" elevation="2" :sortable-id="task.xid" :disabled="loading">
        <v-list dense>
          <v-list-item>
            <v-list-item-content v-if="canSort" style="flex: 0 0 content;" class="font-weight-medium mx-1 dragMe">
              <v-icon color="secondary" style="cursor: grab;">mdi-drag</v-icon>
            </v-list-item-content>
            <v-list-item-content style="flex: 0 0 content;" class="font-weight-medium mx-1">
              <v-icon :color="task.dependentsCompleted?'success':'warning'">mdi-circle</v-icon>
            </v-list-item-content>
            <v-list-item-content class="mx-1">
              <div>
                <span class="font-weight-medium mr-2">{{ task.jobNumber }}</span>
                <span class="text--secondary">{{ task.type?.title }}</span>
              </div>
              <div>
                {{ task.description }}
              </div>
            </v-list-item-content>
            <v-list-item-content style="flex: 0 0 content;" class="font-weight-medium mx-1">
              {{ duration(task.duration) }}
            </v-list-item-content>
            <v-list-item-content style="flex: 0 0 content;" class="font-weight-medium mx-1">
              <v-btn icon large @click="confirmCompleteTask(task)"><v-icon color="primary">mdi-check</v-icon></v-btn>
            </v-list-item-content>
            <v-list-item-content style="flex: 0 0 content;" class="font-weight-medium mx-1">
              <a :href="`/dash/jobs/all/${task.jobId}`"><v-btn icon large><v-icon color="primary">mdi-briefcase</v-icon></v-btn></a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Sortable from 'sortablejs';
export default {
  name: 'Listing',
  components: {},

  props: {
    scheduleId: { type: String, required: true },
    availability: { type: String, required: false, default: "all" }, // all/available/unavailable
  },

  data(){
    return {
      loading: false,
      headers: [
        { text: "Job", value: 'job', sortable: false, width: '10%' },
        { text: "Task", value: 'type', sortable: false, width: '20%' },
        { text: "Description", value: 'description', sortable: false, width: '40%' },
        { text: "Approximate Time", value: 'duration', sortable: false, align: 'center', width: '10%' },
        { text: "Availability", value: 'dependentsCompleted', sortable: false, align: 'center', width: '10%' },
        { text: "Actions", value: '_actions', sortable: false, align: 'right', width: '10%' },
      ],
      tasks: [],
      sortable_: null,
    }
  },

  computed: {
    displayTasks(){
      if(this.availability == "available")
        return this.tasks.filter(t => t.dependentsCompleted);
      if(this.availability == "unavailable")
        return this.tasks.filter(t => !t.dependentsCompleted);
      return this.tasks;
    },
    canSort(){
      // sorting only enabled on 'All' tab as to not lose context of hidden neighboring jobs
      if(this.availability != "all") return false;
      return true;
    }
  },

  methods: {
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['CONFIRM']),
    
    refresh(){
      this.loading = true;
      this.API({ endpoint: `schedules/${this.scheduleId}/tasks` })
      .then(data => {
        this.tasks = data;
        this.makeSortable();
      })
      .finally(() => { this.loading = false; })
    },

    //duration(minutes){ return Moment.duration(minutes, 'minutes').humanize({ m: 60, h: 24 }); },

    confirmCompleteTask(task){
      let message = [
        "Has the following task been completed?\n",
        `${task.type?.title} ${task.jobNumber}`,
        `${task.description}`,
      ].join("\n");
      this.CONFIRM({ title: 'Complete Job', message })
      .then(() => { this.completeTask(task); });
    },

    completeTask(task){
      this.API({ method: 'POST', endpoint: `tasks/${task.xid}/complete` })
      .then(this.refresh);
    },

    duration(minutes) {
      var h = Math.floor(minutes / 60);
      var m = String(minutes - (h * 60)).padStart(2, '0');
      return `${h}:${m}`;
    },

    makeSortable(){
      if(this.sortable_) return; //already initialised
      this.sortable_ = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.dragMe',
        onEnd: this.sorted,
      });
    },

    sorted(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;

      let data = {
        sort_order: this.sortable_.toArray()
      };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `schedules/${this.scheduleId}/sort`, data })
      .then(() => {
        let taskMoved = this.tasks[sortEvent.oldIndex];
        this.tasks.splice(sortEvent.oldIndex, 1);
        this.tasks.splice(sortEvent.newIndex, 0, taskMoved);
      })
      .finally(() => { this.loading = false; })
    },

  },
  mounted(){ this.refresh(); },
}
</script>
