var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "sortable"
  }, _vm._l(_vm.displayTasks, function (task) {
    var _task$type;
    return _c('v-card', {
      key: task.xid,
      staticClass: "my-2",
      attrs: {
        "elevation": "2",
        "sortable-id": task.xid,
        "disabled": _vm.loading
      }
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_vm.canSort ? _c('v-list-item-content', {
      staticClass: "font-weight-medium mx-1 dragMe",
      staticStyle: {
        "flex": "0 0 content"
      }
    }, [_c('v-icon', {
      staticStyle: {
        "cursor": "grab"
      },
      attrs: {
        "color": "secondary"
      }
    }, [_vm._v("mdi-drag")])], 1) : _vm._e(), _c('v-list-item-content', {
      staticClass: "font-weight-medium mx-1",
      staticStyle: {
        "flex": "0 0 content"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": task.dependentsCompleted ? 'success' : 'warning'
      }
    }, [_vm._v("mdi-circle")])], 1), _c('v-list-item-content', {
      staticClass: "mx-1"
    }, [_c('div', [_c('span', {
      staticClass: "font-weight-medium mr-2"
    }, [_vm._v(_vm._s(task.jobNumber))]), _c('span', {
      staticClass: "text--secondary"
    }, [_vm._v(_vm._s((_task$type = task.type) === null || _task$type === void 0 ? void 0 : _task$type.title))])]), _c('div', [_vm._v(" " + _vm._s(task.description) + " ")])]), _c('v-list-item-content', {
      staticClass: "font-weight-medium mx-1",
      staticStyle: {
        "flex": "0 0 content"
      }
    }, [_vm._v(" " + _vm._s(_vm.duration(task.duration)) + " ")]), _c('v-list-item-content', {
      staticClass: "font-weight-medium mx-1",
      staticStyle: {
        "flex": "0 0 content"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "large": ""
      },
      on: {
        "click": function click($event) {
          return _vm.confirmCompleteTask(task);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-check")])], 1)], 1), _c('v-list-item-content', {
      staticClass: "font-weight-medium mx-1",
      staticStyle: {
        "flex": "0 0 content"
      }
    }, [_c('a', {
      attrs: {
        "href": "/dash/jobs/all/".concat(task.jobId)
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "large": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-briefcase")])], 1)], 1)])], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }